/* eslint-disable */
import Vue from 'vue';
import Router from 'vue-router';
import PageNotFound from '@/components/page-not-found.vue';
import CompanyPage from '@/components/company-page.vue';
import CompanyTweetPage from '@/components/company-tweet-page.vue';
import CompanyScreener from '@/components/company-screener.vue';
import CompanyFilingDetail from '@/components/company-filing-detail.vue';
import LandingPage from '@/components/landing-page.vue';
import LandingPageNew from '@/components/landing-page-new.vue';
import Knowledge from '@/components/knowledge.vue';
import UserRegistration from '@/components/user-registration.vue';
import UserCheckYourMail from '@/components/user-check-your-mail.vue';
import WaitlistRegistration from '@/components/waitlist-registration.vue';
import WaitlistUnsubscribe from '@/components/waitlist-unsubscribe.vue';
import WaitlistVerification from '@/components/waitlist-verification.vue';
import WaitlistCheckYourMail from '@/components/waitlist-check-your-mail.vue';
import UserAccountVerification from '@/components/user-account-verification.vue';
import UserRequestResetPassword from '@/components/user-request-reset-password.vue';
import UserResetPassword from '@/components/user-reset-password.vue';
import TweetGenerator from '@/components/tweet-generator.vue';
import SiteStatistics from '@/components/site-statistics.vue';
import UserLogin from '@/components/user-login.vue';
import UserDashboard from '@/components/user-dashboard.vue';
import PrivacyPolicy from '@/components/privacy-policy.vue';
import TermsAndConditions from '@/components/terms-and-conditions.vue';
import TermsAndConditions20July23 from '@/components/terms-and-conditions-20th-july-2023.vue';
import Imprint from '@/components/imprint.vue';
import ErrorPage from '@/components/error-page.vue';
import FrequentlyAskedQuestions from '@/components/faq.vue';
import Premium from '@/components/premium.vue';
import Admin from '@/components/admin.vue';
import Onboarding from '@/components/onboarding.vue';
import DebugSymbolsPageFinnhub from '@/components/debug-symbols-page-finnhub.vue';
import { sessionManager } from '@/main';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/profile/:symbol',
      component: CompanyPage,
      props: true,
      props: (route) => ({ symbol: route.params.symbol, logo: false }),
    },
    {
      path: '/profile/:symbol/:tab',
      component: CompanyPage,
      props: true,
      props: (route) => ({ symbol: route.params.symbol, tab: route.params.tab, logo: false }),
    },
    {
      path: '/finnhubSymbols/',
      component: DebugSymbolsPageFinnhub,
      props: true,
      beforeEnter: (to, from, next) => {
        const isAuthenticated = () => {
          return sessionManager.isLoggedin();
        }
        const isOnboardingDone = () => {
          return sessionManager.isOnboardingDone();
        }
        if (!isAuthenticated()) {
          next({ name: 'login' })
        } else if(!isOnboardingDone()) {
          next({ name: 'onboarding' })
        } else {
          next()
        }
      }
    },
    {
      path: '/tweet/:symbol/',
      component: CompanyTweetPage,
      props: true,
      props: (route) => ({ symbol: route.params.symbol, logo: true }),
      beforeEnter: (to, from, next) => {
        const isAuthenticated = () => {
          return sessionManager.isLoggedin();
        }
        const isAdmin = () => {
          return sessionManager.isAdmin();
        }
        if (!isAuthenticated() || !isAdmin()) {
          next({ name: 'login' })
        } 
        else
        {
          next()
        }
      }
    },
    {
      path: '/search',
      name: 'search',
      component: CompanyScreener,
      beforeEnter: (to, from, next) => {
        const isAuthenticated = () => {
          return sessionManager.isLoggedin();
        }
        const isOnboardingDone = () => {
          return sessionManager.isOnboardingDone();
        }
        if (!isAuthenticated()) {
          next({ name: 'login' })
        } else if(!isOnboardingDone()) {
          next({ name: 'onboarding' })
        } else {
          next()
        }
      }
    },
    {
      path: '/knowledge',
      name: 'knowledge',
      component: Knowledge,
    },
    {
      path: '/waitlist',
      name: 'waitlist',
      component: WaitlistRegistration,
    },
    {
      path: '/login/:email?/:welcome?',
      name: 'login',
      component: UserLogin,
      props: true
    },
    {
      path: '/requestResetPassword',
      name: 'requestResetPassword',
      component: UserRequestResetPassword,
    },
    {
      path: '/resetPassword',
      name: 'resetPassword',
      component: UserResetPassword,
      props: (route) => ({ email: route.query.email, code: route.query.code }),
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: UserDashboard,
      beforeEnter: (to, from, next) => {
        const isAuthenticated = () => {
          return sessionManager.isLoggedin();
        }
        const isOnboardingDone = () => {
          return sessionManager.isOnboardingDone();
        }
        if (!isAuthenticated()) {
          next({ name: 'login' })
        } else if(!isOnboardingDone()) {
          next({ name: 'onboarding' })
        } else {
          next()
        }
      }
    },
    {
      path: '/verifyWaitlist',
      component: WaitlistVerification,
      props: (route) => ({ email: route.query.email, code: route.query.code }),
    },
    {
      path: '/verifyUserAccount',
      component: UserAccountVerification,
      props: (route) => ({ email: route.query.email, code: route.query.code }),
    },
    {
      path: '/unsubscribe',
      component: WaitlistUnsubscribe,
      props: (route) => ({ email: route.query.email, code: route.query.code }),
    },
    {
      path: '/checkMailWaitlist',
      name: 'checkMailWaitlist',
      component: WaitlistCheckYourMail,
      props: true
    },
    {
      path: '/checkMailRegistration',
      name: 'checkMailRegistration',
      component: UserCheckYourMail,
      props: true
    },
    {
      path: '/filing',
      name: 'filing',
      component: CompanyFilingDetail,
      props: (route) => ({ url: route.query.url }),
    },
    {
      path: '/privacy',
      name: 'privacy',
      component: PrivacyPolicy,
    },
    {
      path: '/terms',
      name: 'terms',
      component: TermsAndConditions,
    },
    {
      path: '/terms20July23',
      name: 'terms20July23',
      component: TermsAndConditions20July23,
    },
    {
      path: '/imprint',
      name: 'imprint',
      component: Imprint,
    },
    {
      path: '/error',
      name: 'error',
      component: ErrorPage,
    },
    {
      path: '/tweet',
      name: 'tweet',
      component: TweetGenerator,
    },
    {
      path: '/statistics',
      name: 'statistics',
      component: SiteStatistics,
    },
    /* disabled registration
    {
      path: '/register/:email/:code',
      component: UserRegistration,     
      props: route => {
        return { email: route.params.email, code: route.params.code }
      },
    },
    {
      path: '/register',
      component: UserRegistration,     
      props: route => {
        return { email: '', code: '', force: false }
      },
    },
    {
      path: '/forceRegister',
      component: UserRegistration,     
      props: route => {
        return { email: '', code: '', force: true }
      },
    },
    */
    {
      path: '/faq',
      name: 'faq',
      component: FrequentlyAskedQuestions,
    },
    {
      path: '/onboarding',
      name: 'onboarding',
      component: Onboarding,
      props: {
        trackSteps: true
      }
    },
    {
      path: '/tour',
      name: 'onboarding',
      component: Onboarding,
      props: {
        trackSteps: false
      }
    },
    {
      path: '/admin',
      name: 'admin',
      component: Admin,
    },
    {
      path: '/premium',
      name: 'premium',
      component: Premium,
    },
    /*{
      path: '/*',
      name: 'landingPage',
      component: LandingPage,
    },
    */
    {
      path: '/*',
      name: 'landingPageNew',
      component: LandingPageNew,
    },
    {
      path: '/sitemap.xml',
      beforeEnter: (to, from, next) => {
        window.location.href = '/api/HttpTriggerSitemap';
      }
    },
    {
      path: '*',
      component: PageNotFound,
    },
  ],
});

export default router;

